import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Reverse Hyperextensions 8-8-8`}</p>
    <p>{`&`}</p>
    <p>{`Hollow Rocks :30-:30-:30`}</p>
    <p>{`then,`}</p>
    <p>{`Deadlifts 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Dianne”`}</strong></p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Deadlifts (225/155)`}</p>
    <p>{`HSPU’s`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your 4 person (2 men/2 women) teams together for True Grit 6!
 RX and Scaled teams will battle it out for the 6th year at the Ville
for cash prizes and other goodies.  September 15th.  Register by
September 1st to ensure you get your t shirt on the day of the
competition.  If you’d like to compete but don’t have a team we have a
signup sheet by the white board.  If you’d like to help judge please add
your name to the sheet.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      